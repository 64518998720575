import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import enJson from '../../public/overseacdn/passinArk/homepage/intl/en-US.json';
import zhJson from '../../public/overseacdn/passinArk/homepage/intl/zh-CN.json';

const useLocale = () => {
  const { locale } = useRouter();
  const [messages, setMessages] = useState(enJson);

  useEffect(() => {
    if (locale === 'zh-CN') {
      setMessages(zhJson);
    } else {
      setMessages(enJson);
    }
  }, [locale]);

  return { locale, messages };
};

export default useLocale;
