import React, { createContext, useRef, useContext, PropsWithChildren, useState } from 'react';

interface HValidation {
  history: string;
  setHistory(pathname: string): void;

  contactSuccess: boolean;
  setContactSuccess(value: boolean): void;
}

const AppContext = createContext<HValidation>({} as HValidation);
export const AppProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [contactSuccess, setContactSuccess] = useState(false);
  const history = useRef<string>('');

  const setHistory = (pathname: string) => {
    history.current = pathname;
  };

  return (
    <AppContext.Provider
      value={{
        history: history.current,
        setHistory,
        contactSuccess,
        setContactSuccess,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export function useApp(): HValidation {
  const context = useContext(AppContext);
  return context;
}
