import Head from 'next/head';
import { useRouter } from 'next/router';

const csp = `script-src
https://www.googletagmanager.com
https://overseacdn.ydstatic.com
https://googleads.g.doubleclick.net
https://www.googleadservices.com
'sha256-zOFC128cxZHiV+8OvZMAcUWpdZ836T534LmenyJet9o='
blob:
'unsafe-eval' 'self';`;

const SEO = (props: { messages: Record<string, any> }) => {
  const { messages } = props;
  const router = useRouter();
  const getPathname = (pathname: string) => {
    switch (pathname) {
      case '/':
        return 'home';
      case '/case/[slug]':
        return 'case';
      case '/brands':
        return 'about';
      default:
        return pathname.replace(/\//g, '');
    }
  };
  const pathName = getPathname(router.pathname);
  const title = messages[`${pathName}.meta.title`] || 'Youdao Ads';
  const keyword = messages[`${pathName}.meta.keywords`] || 'Youdao Ads';
  const desc = messages[`${pathName}.meta.desc`] || 'Youdao Ads';

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=0" />
      <meta name="google-site-verification" content="V3_pgpj662UQpKxNRkVuOfb3s46wfWZwsyVikPO3s-U" />
      <meta httpEquiv="Content-Security-Policy" content={csp} />
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="keywords" content={keyword} />
      <meta name="description" content={desc} />
      <link
        rel="shortcut icon"
        href={`${process.env.STATIC_ASSETS_URL}/overseacdn/passinArk/homepage/favicon.ico`}
        type="image/ico"
      />
    </Head>
  );
};

export default SEO;
