import { FormattedMessage } from 'react-intl';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import useCookie from '@/hooks/useCookie';
import { gaEventName, addGtagEvent } from '@/utils/addGA';
import { useApp } from '@/provider/App';
import styles from './index.module.css';
import ExpandIcon from '@/components/common/icons/ExpandIcon';

const routerIndex = {
  '/case': 0,
  '/case/[slug]': 0,
  '/creators': 1,
  '/brands': 2,
  '/about': 2,
};

const HeaderPage = () => {
  const router = useRouter();
  const { pathname, asPath, query, locale } = router;
  const [showMenu1, setShowMenu1] = useState<boolean>(false);
  const [showMenu2, setShowMenu2] = useState<boolean>(false);
  const { showCookie, handleConfirm } = useCookie();
  const { contactSuccess, setContactSuccess } = useApp();

  const handleMouseEnter = (index) => {
    if (index !== 2 && index !== 3) return;
    if (index === 2) {
      setShowMenu1(true);
      return;
    }
    setShowMenu2(true);
  };

  const handleMouseLeave = (index) => {
    if (index !== 2 && index !== 3) return;
    if (index === 2) {
      setShowMenu1(false);
      return;
    }
    setShowMenu2(false);
  };

  const navList = [
    {
      nav: (
        <Link href='/case' key='case'>
          <div>
            <FormattedMessage id="header.nav1" defaultMessage="Cases" />
          </div>
          <div>
            <FormattedMessage id="header.nav1" defaultMessage="Cases" />
          </div>
        </Link>
      ),
    },
    {
      nav: (
        <Link href={`/creators`} key='creators'>
          <div>
            <FormattedMessage id="header.nav2" defaultMessage="Creators" />
          </div>
          <div>
            <FormattedMessage id="header.nav2" defaultMessage="Creators" />
          </div>
        </Link>
      ),
    },
    {
      nav: (
        <span className="relative" key='about'>
          <div>
            <FormattedMessage id="header.nav3" defaultMessage="About us" />
            <Image
              src={`/overseacdn/passinArk/homepage/img/layouts/arrows.svg`}
              alt="arrow"
              width={16}
              height={16}
              className="inline-block"
            />
          </div>
          <div>
            <FormattedMessage id="header.nav3" defaultMessage="About us" />
            <Image
              src={`/overseacdn/passinArk/homepage/img/layouts/arrows.svg`}
              alt="arrow"
              width={16}
              height={16}
              className="inline-block"
            />
          </div>
        </span>
      ),
      otherDom: (
        <div className="absolute top-0 left-[-66px] h-[85px] w-[120px] bg-black text-center">
          <Link className="my-3 block cursor-pointer hover:text-active-color" href='/brands'>
            <FormattedMessage id="header.nav3-1" defaultMessage="Brands" />
          </Link>
          <Link className="my-3 mt-0 block cursor-pointer hover:text-active-color" href='/about'>
            <FormattedMessage id="header.nav3-2" defaultMessage="Creators" />
          </Link>
        </div>
      ),
    },
    {
      nav: (
        <span key='lan'>
          <div>
            {locale === 'zh-CN' ? '中文' : 'English'}
            <Image
              src={`/overseacdn/passinArk/homepage/img/layouts/arrows.svg`}
              alt="arrow"
              width={16}
              height={16}
              className="inline-block"
            />
          </div>
          <div>
            {locale === 'zh-CN' ? '中文' : 'English'}
            <Image
              src={`/overseacdn/passinArk/homepage/img/layouts/arrows.svg`}
              alt="arrow"
              width={16}
              height={16}
              className="inline-block"
            />
          </div>
        </span>
      ),
      otherDom: (
        <div className="absolute top-0 left-[-59px] h-[85px] w-[120px] bg-black text-center">
          <Link
            className="my-3 block cursor-pointer hover:text-active-color"
            href={{
              pathname,
              query,
            }}
            locale='en-US'
          >
            {'English'}
          </Link>
          <Link
            className="my-3 mt-0 block cursor-pointer hover:text-active-color"
            href={{
              pathname,
              query,
            }}
            locale='zh-CN'
          >
            {'中文'}
          </Link>
        </div>
      ),
    },
    {
      nav: (
        <Link
          onClick={() => {
            addGtagEvent({
              event: gaEventName.CLICK,
              options: {
                page_location: window.location.href,
                click_id: 'contact_click',
                page_from: 'passionArk',
              },
            });
            contactSuccess && setContactSuccess(false);
          }}
          href='/contact'
          className={styles.contact}
          key='contact'
        >
          <button className="h-[32px] w-[96px] cursor-pointer bg-active-color">
            <FormattedMessage id="header.nav5" defaultMessage="Contact" />
          </button>
          <span className='absolute bottom-0 left-0 inline-block'>
            <ExpandIcon />
          </span>
          <span className='absolute bottom-0 right-0 inline-block -rotate-90'>
            <ExpandIcon />
          </span>
          <span className='absolute top-0 left-0 inline-block rotate-90'>
            <ExpandIcon />
          </span>
          <span className='absolute top-0 right-0 inline-block rotate-180'>
            <ExpandIcon />
          </span>
        </Link>
      ),
    },
  ];

  return (
    <header className='sticky top-0 z-[999] flex h-[70px] w-full min-w-[1200px] justify-center bg-black'>
      <main className="flex h-[70px] min-w-[1200px] flex-nowrap items-center justify-between">
        <Link href='/'>
          <Image
            src={`/overseacdn/passinArk/homepage/img/layouts/logo.svg`}
            alt="logo"
            width={192}
            height={30}
            className="cursor-pointer"
          />
        </Link>
        <section className="flex min-w-[360px] flex-nowrap items-center justify-between text-white">
          {navList.map((item, index) => (
            <span
              className={`flex flex-col flex-nowrap items-center justify-between ${styles.nav} min-w-[120px] `}
              key={index}
              onMouseEnter={handleMouseEnter.bind(null, index)}
              onMouseLeave={handleMouseLeave.bind(null, index)}
            >
              <span className={`w-fit ${index === 4 ? '' : 'mt-2'}`}>
                <span
                  className={`${index !== 4 ? 'h-[24px] overflow-hidden' : ''} inline-block w-fit `}
                >
                  <motion.div
                    whileHover={index !== 4 ? { y: -24 } : { y: 0 }}
                    transition={{ duration: 0.2 }}
                    className={`inline-block cursor-pointer text-center`}
                  >
                    {item.nav}
                  </motion.div>
                </span>
                {index !== 4 && (
                  <div className="overflow-hidden">
                    <div
                      className={
                        routerIndex[pathname] === index
                          ? styles.activeRouterLine
                          : styles.activeLine
                      }
                    />
                  </div>
                )}
              </span>

              <AnimatePresence>
                {showMenu1 && index === 2 && item.otherDom && (
                  <motion.div
                    className="relative"
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      duration: 0.25,
                    }}
                  >
                    {item.otherDom}
                  </motion.div>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {showMenu2 && index === 3 && item.otherDom && (
                  <motion.div
                    className="relative"
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      duration: 0.25,
                    }}
                  >
                    {item.otherDom}
                  </motion.div>
                )}
              </AnimatePresence>
            </span>
          ))}
        </section>
      </main>
      {showCookie && (
        <div
          className="fixed bottom-3 left-0 flex h-[60px] items-center  justify-center bg-active-color"
          style={{
            clipPath: 'polygon(15px 0, 100% 0, 100% 45px, calc(100% - 15px) 100%, 0 100%, 0 15px)',
            // marginLeft: 'calc(50vw - 600px)',
          }}
        >
          <span
            className="ml-6 cursor-pointer"
            onClick={() => {
              window &&
                window.open(
                  `${window.location.origin + '/' + router.locale}/private-policy`,
                  '_blank',
                );
            }}
          >
            <FormattedMessage
              id="header.cookie.content"
              defaultMessage="By using this website you automatically agree to our cookie policy"
            />
          </span>
          <button
            className="ml-5 mr-6 h-[32px] w-[77px] cursor-pointer bg-white text-[#FE0101] hover:bg-[#FFD4d7]"
            onClick={handleConfirm}
          >
            <FormattedMessage id="header.cookie.button" defaultMessage="Got it!" />
          </button>
        </div>
      )}
    </header>
  );
};

export default HeaderPage;
