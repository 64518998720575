const ExpandIcon = () => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 0.5L1 14.5H15" stroke="url(#paint0_linear_532_6263)" strokeWidth="2" />
      <defs>
        <linearGradient
          id="paint0_linear_532_6263"
          x1="0.999999"
          y1="1.15321"
          x2="12.4993"
          y2="14.9524"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0436232" stopColor="#FF2937" stopOpacity="0" />
          <stop offset="0.322052" stopColor="#FF2937" />
          <stop offset="0.678405" stopColor="#FF2937" />
          <stop offset="0.967691" stopColor="#FF2937" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ExpandIcon;
