import Image from 'next/image';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import cls from 'classnames';
import Contact from '@/components/layouts/Contact';
import {
  InstagramIcon,
  TikTokIcon,
  FaceBookIcon,
  TwitterIcon,
  YoutubeIcon,
  AimIcon,
} from '@/components/common/Icon';

const FooterPage = () => {
  const router = useRouter();
  const isContact = router.pathname === '/contact';

  const iconList = [
    {
      icon: <InstagramIcon />,
      url: 'https://www.instagram.com/passion_ark_official/',
    },
    {
      icon: <TikTokIcon />,
      url: 'https://www.tiktok.com/@passion_ark?_t=8WRAIPT4oYQ&_r=1',
    },
    {
      icon: <FaceBookIcon />,
      url: 'https://www.facebook.com/profile.php?id=100086409350326',
    },
    {
      icon: <TwitterIcon />,
      url: 'https://twitter.com/passion_ark?t=1jINw87D-DW39DuOaX9KFA&s=09',
    },
    {
      icon: <YoutubeIcon />,
      url: 'https://www.youtube.com/channel/UCxQrmQl7b0TTdkmaa5bYm6w',
    },
  ];

  const serviceList = [
    {
      id: 'footer.services.email',
      defaultMessage: 'Email：passionark@rd.netease.com',
    },
    {
      id: 'footer.services.phone',
      defaultMessage: 'Tel：400-6789-163',
    },
    {
      id: 'footer.services.worktime',
      defaultMessage: 'Work hours：Mon.-Fri. 9.30-18:00 (UTC+8)',
    },
    {
      id: 'footer.services.partner',
      defaultMessage: 'Partner：',
    },
  ];
  return (
    <footer
      className={cls('w-full min-w-[1200px] bg-black flex flex-wrap justify-center align-middle', {
        'h-[600px]': !isContact,
      })}
    >
      {!isContact && <Contact />}
      <section className="relative h-[524px] w-[100vw] min-w-[1200px] overflow-hidden pt-[200px]">
        <main className="relative z-10 my-0 mx-auto w-[1100px]">
          <div className="relative w-full">
            <Image
              src={`/overseacdn/passinArk/homepage/img/layouts/logo.svg`}
              alt="logo"
              width={384}
              height={60}
              className="my-0 mx-auto block"
            />
            <span className="absolute top-[50px] left-1/2 translate-x-[-263px] scale-75">
              <AimIcon />
            </span>
          </div>
          <div className="mt-[30px] flex cursor-pointer justify-center">
            {iconList.map((item, index) => (
              <span
                key={index}
                className="mx-[10px] inline-block text-white hover:text-active-color"
                onClick={() => {
                  window && window.open(item.url, '_blank');
                }}
              >
                {item.icon}
              </span>
            ))}
          </div>
          <p className="mt-[20px] text-center">
            <FormattedMessage
              id="footer.title"
              defaultMessage="A Global leading content studio powered by a global network of top influencers"
            />
          </p>
          <div className="mt-[30px] flex justify-center">
            {serviceList.map((item, index) => (
              <span
                key={item.id}
                className={cls('px-4', 'whitespace-nowrap', 'border-r-[1px]', {
                  ['border-r-0']: index === 3,
                })}
              >
                <FormattedMessage id={item.id} defaultMessage={item.defaultMessage} />
                {item.id === 'footer.services.partner' &&
                  (router.locale === 'zh-CN' ? (
                    <a href="https://youdaoads.com/zh-CN" target="_blank" rel="noopener noreferrer">
                      https://youdaoads.com
                    </a>
                  ) : (
                    <a href="https://youdaoads.com/en-US" target="_blank" rel="noopener noreferrer">
                      https://youdaoads.com
                    </a>
                  ))}
              </span>
            ))}
          </div>
          <div className="mt-[40px] flex h-[40px] w-full justify-center border-t border-[rgba(255,255,255,0.1)] align-middle opacity-70">
            <a
              className="my-[16px] cursor-pointer "
              href="https://beian.miit.gov.cn/"
              target='_blank'
              rel="noreferrer"
            >
              {'粤ICP备2023027124号-1'}
            </a>
            <span className="my-[16px] pl-[8px]">{' · '}</span>
            <span
              className="my-[16px] cursor-pointer pl-[8px]"
              onClick={() => {
                window &&
                  window.open(
                    `${window.location.origin + '/' + router.locale}/private-policy`,
                    '_blank',
                  );
              }}
            >
              <FormattedMessage id='footer.policy.privacy' defaultMessage='Privacy Policy' />
            </span>
          </div>
        </main>
        {isContact && (
          <Image
            src={`/overseacdn/passinArk/homepage/img/layouts/footer_darklines.svg`}
            alt="logo"
            width={600}
            height={468}
            className='absolute left-1/2 bottom-0 -translate-x-1/2'
          />
        )}
        {!isContact && (
          <>
            <div className="absolute left-[-107px] bottom-[-147px] z-0 h-[332px] w-[1360px] bg-[#FF2937] opacity-[0.25] blur-250"></div>
            <div className="absolute right-[-325px] bottom-[-203px] z-0 h-[378px] w-[1382px] bg-[#0029CC] opacity-40 blur-250"></div>
          </>
        )}
      </section>
    </footer>
  );
};

export default FooterPage;
