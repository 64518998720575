import { useState, useEffect } from 'react';

const useCookie = () => {
  const [showCookie, setShowCookie] = useState<boolean>(false);
  useEffect(() => {
    const hasConfirm = localStorage.getItem('passionark_show_cookie');
    if (!hasConfirm) {
      setShowCookie(true);
    }
  }, []);

  const handleConfirm = () => {
    if (typeof window === 'undefined') return;
    localStorage.setItem('passionark_show_cookie', 'hide');
    setShowCookie(false);
  };

  return { showCookie, handleConfirm };
};

export default useCookie;
