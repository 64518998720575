import Image from 'next/image';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';
import styles from './Contact.module.css';
import type { Variants } from 'framer-motion';
import { motion } from 'framer-motion';
import { ArrowIcon } from '../common/Icon';
import { AimIcon } from '@/components/common/Icon';
import { formatNewLine } from '@/components/common/Intl';
import { gaEventName, addGtagEvent } from '@/utils/addGA';

const listVariants: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.05,
      staggerChildren: 0.4,
    },
  },
};

const itemVariants: Variants = {
  hidden: { y: 50, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const Contact = () => {
  const router = useRouter();
  const content = () => {
    if (router.pathname === '/about') {
      return 'footer.contact.text2';
    } else if (router.pathname === '/brands') {
      return 'footer.contact.text3';
    }
    return 'footer.contact.text1';
  };
  return (
    <motion.section
      className="relative flex  w-[1200px] justify-center align-middle"
      variants={listVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
    >
      <motion.div
        className={styles['contact-title']}
        variants={itemVariants}
        transition={{
          duration: 0.5,
        }}
      >
        CONTACT
      </motion.div>
      <div className="mt-[54px]">
        <motion.div
          className={styles['contact-border']}
          variants={itemVariants}
          transition={{
            duration: 0.5,
          }}
        >
          <div className={styles['contact-content']}>
            <motion.div
              className={`relative ml-[86px] inline-block w-[600px] ${
                router.locale === 'zh-CN' ? 'mt-[110px]' : 'mt-[67px]'
              }`}
              variants={itemVariants}
              transition={{
                duration: 0.5,
                delay: 0.4,
              }}
            >
              <Image
                src="/overseacdn/passinArk/homepage/img/layouts/star.svg"
                alt=""
                width={70}
                height={70}
                className="absolute top-[-15px] left-[-30px]"
              />
              <h4
                className={`relative z-10 mb-[28px] ${
                  router.locale === 'zh-CN' ? 'text-[68px]' : 'text-[56px]'
                }`}
              >
                <FormattedMessage
                  id="footer.contact.title"
                  defaultMessage="Contact us"
                  values={{
                    newLine: formatNewLine,
                  }}
                />
              </h4>
              <motion.p
                className="text-[48px] font-thin leading-6"
                variants={itemVariants}
                transition={{
                  duration: 0.5,
                  delay: 0.8,
                }}
              >
                <FormattedMessage id={content()} defaultMessage="Let’s work together!" />
              </motion.p>
            </motion.div>
            <motion.div
              className="inline-block"
              variants={itemVariants}
              transition={{
                duration: 0.5,
                delay: 0.4,
              }}
            >
              <span className="inline-block h-[160px] w-[160px] rounded-full bg-active-color"></span>
              <Link
                className="relative inline-block h-[160px] w-[160px] translate-x-[-70px] cursor-pointer rounded-full border"
                onClick={() => {
                  addGtagEvent({
                    event: gaEventName.CLICK,
                    options: {
                      page_location: window.location.href,
                      click_id: 'contact_click',
                      page_from: 'passionArk',
                    },
                  });
                }}
                href='/contact'
              >
                <span className="circle-container absolute top-[6px] left-[7px] z-30 h-[146px] w-[146px]">
                  <Image
                    src={`/overseacdn/passinArk/homepage/img/layouts/contact_circle_${
                      router.locale === 'zh-CN' ? 'zh' : 'en'
                    }.svg`}
                    alt=""
                    width={146}
                    height={146}
                    className="circle-around"
                  />
                </span>
                <span className="absolute top-[55px] left-[55px] z-20 flex h-[48px] w-[48px] items-center justify-center rounded-full bg-white text-black">
                  <ArrowIcon />
                </span>
              </Link>
            </motion.div>
          </div>
        </motion.div>
      </div>
      <span className="absolute bottom-[-18px] left-1/2 translate-x-5 scale-75">
        <AimIcon />
      </span>
      <span className="absolute top-0 left-0 scale-50">
        <AimIcon />
      </span>
    </motion.section>
  );
};

export default Contact;
