import Script from 'next/script';
import { GA_ID, GA_ADS_ID } from '@/constants/common';

/* eslint-disable */
const GoogleAnalytics = () => {
  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());

          gtag('config', '${GA_ID}');
          gtag('config', '${GA_ADS_ID}');
        `,
        }}
      />
    </>
  );
};

export default GoogleAnalytics;
