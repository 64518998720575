interface getTextDomWidthProps {
  str: string;
  fontSize?: number | string;
  fontFamily?: string;
}
export const getTextDomWidth = ({ str, fontSize, fontFamily }: getTextDomWidthProps) => {
  if (!fontSize) {
    fontSize = 16;
  }
  const spanEle = document.createElement('span');
  if (typeof fontSize === 'string') {
    spanEle.style.fontSize = fontSize;
  } else if (typeof fontSize === 'number') {
    spanEle.style.fontSize = fontSize + 'px';
  }
  if (fontFamily) {
    spanEle.style.fontFamily = fontFamily;
  }
  spanEle.style.whiteSpace = 'nowrap';
  spanEle.innerHTML = str;
  document.body.appendChild(spanEle);
  const width = spanEle.offsetWidth;
  spanEle.remove();
  return width;
};

export function simpleThrottle<T extends unknown[]>(func: (...args: T) => void, interval = 20) {
  let lastTime = 0;
  return (...args: T) => {
    const now = Date.now();
    if (now - lastTime >= interval) {
      func(...args);
      lastTime = now;
    }
  };
}

export const getDate = (date: Date): string => {
  const Y = date.getFullYear() + '-';
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  const D = date.getDate();
  return Y + M + D;
};

export const vw2px = (num: number) => {
  return (num * document.documentElement.clientWidth) / 100;
};
