var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"67514fcd305e0de5bdb174a013c0bad2fc06d975"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/overseacdn/passinArk";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN;
const SENTRY_RELEASE = process.env.npm_package_version;

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
  enabled: Boolean(SENTRY_DSN),
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    /^AbortError: The play\(\) request was interrupted/,
    /^AbortError: The operation was aborted/,
  ],
  release: SENTRY_RELEASE,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
