import { getDate } from '@/utils/utils';

export const gaEventName = {
  PAGE_VIEW: 'page_view',
  UNIQUE_VISITOR: 'unique_visitor',
  CLICK: 'click',
} as const;

type ValueOf<T> = T[keyof T];
type GaEventName = ValueOf<typeof gaEventName>;

export const gtagHasLoaded = () => {
  return Boolean(document.getElementById('gtag-init'));
};

interface addGtagEventProps {
  event: GaEventName;
  options?: any;
  isUV?: boolean;
  uvId?: string;
}

export function addGtagEvent({ event, options, isUV = false, uvId }: addGtagEventProps) {
  if (!gtagHasLoaded()) return;

  //发送请求
  const postGtag = (event: GaEventName, options?: any) => {
    if (options) {
      gtag('event', event, options);
      return;
    }
    gtag('event', event);
  };
  if (!uvId) {
    uvId = event;
  }
  //uv处理
  if (isUV) {
    const lastLoginDate = localStorage.getItem(uvId);
    if (!lastLoginDate || new Date(lastLoginDate) < new Date(getDate(new Date()))) {
      postGtag(event, options);
      localStorage.setItem(uvId, getDate(new Date()));
    }
    return;
  }
  postGtag(event, options);
}
