import { Fragment, useEffect } from 'react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import cls from 'classnames';
import { IntlProvider } from 'react-intl';
import GoogleAnalytics from '@/components/common/GoogleAnalytics';
import Layout from '@/layouts';
import { AppProvider } from '@/provider/App';
import useLocale from '@/hooks/useLocale';
import SEO from '@/components/common/SEO';
import 'src/styles/global.css';

const MyApp = ({ Component, pageProps }: AppProps) => {
  const { locale, messages } = useLocale();
  const { pathname } = useRouter();
  const isPolicy = pathname === '/private-policy' || pathname === '/private-policy-minors';

  const IntlText = ({ children }) => (
    <span className={cls(locale, 'inline-block')}>{children}</span>
  );

  return (
    <Fragment>
      <SEO messages={messages} />
      <GoogleAnalytics />
      {isPolicy ? (
        <div className='bg-white'>
          <Component {...pageProps} />
        </div>
      ) : (
        <IntlProvider locale={locale} messages={messages} textComponent={IntlText}>
          <AppProvider>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </AppProvider>
        </IntlProvider>
      )}
    </Fragment>
  );
};

export default MyApp;
